import React from 'react'
import { View, Text } from 'react-native'
import { EntityTypeFieldPicker } from './EntityTypeFieldPicker'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { ListItemLinkButton } from '../ListItemLinkButton'
import { Divider } from 'react-native-elements'
import spacing from '../../styles/spacing'
import shared from '../../styles/shared'
import MSFESButton from '../MSFESButton'
import colors from '../../styles/colors'
import { EntityField } from '../EntityField'
import { flattenField } from '../../helpers/entity'

export const EntityTypeFieldValueMapper = React.forwardRef((props, ref) => {
  const { mappedFields = [] } = props

  const entity1Fields = _.get(props.sourceEntityType, 'fields', [])

  const entityFieldMapSourceChosen = (value, index) => {
    const mappedFields = [...props.mappedFields]
    mappedFields[index].source = value
    props.onFieldmapChanged(mappedFields)
  }
  const entityFieldMapValueSet = (value, index) => {
    const mappedFields = [...props.mappedFields]
    mappedFields[index].value = value
    props.onFieldmapChanged(mappedFields)
  }

  const onAddMappedFieldPressed = () => {
    const _mappedFields = [...mappedFields]
    _mappedFields.push({ source: null, value: null, offline_id: uuidv4 })
    props.onFieldmapChanged(_mappedFields)
  }

  const deleteMapRow = (mappedFieldSet, index) => {
    const _mappedFields = [...mappedFields]
    _mappedFields.splice(index, 1)

    props.onFieldmapChanged(_mappedFields)
  }

  const textSymbol = [
    {
      backgroundColor: colors.inputBottomBorder,
      paddingHorizontal: 4,
      paddingVertical: 2,
      borderRadius: 4
    }
  ]

  return (
    <View style={[shared.debugOff, { flexDirection: 'column' }]}>
      {mappedFields.map((mappedFieldSet, index) => {
        const sourceValue = _.get(mappedFieldSet.source, 'id', null)
        const targetValue = mappedFieldSet.value

        const sourceTitle =
          '' + _.get(mappedFieldSet.source, 'field_data.title', null)

        const { transformedValue: flatValue } = flattenField(
          true,
          targetValue,
          mappedFieldSet.source?.field_data?.type,
          null
        )
        return (
          <View
            key={index}
            style={[{ flexDirection: 'row', flex: 1 }, shared.debugOff]}
          >
            {sourceValue && targetValue && (
              <View style={{ flex: 1 }}>
                <Text>
                  <Text style={textSymbol}>{sourceTitle}</Text> maps to{' '}
                  <Text style={textSymbol}>{flatValue}</Text>
                </Text>
              </View>
            )}
            {!(sourceValue && targetValue) && (
              <View style={{ flex: 1 }}>
                <View style={[{ paddingBottom: spacing.m1 }, { flex: 1 }]}>
                  <EntityTypeFieldPicker
                    simplifyObject={false}
                    entityTypeFields={entity1Fields}
                    placeholder="Source Field"
                    value={'' + sourceValue}
                    onEntityTypeFieldSelected={(value) =>
                      entityFieldMapSourceChosen(value, index)
                    }
                  />
                </View>

                {mappedFieldSet.source && (
                  <EntityField
                    mode={'edit'}
                    entity={{}}
                    definition={props.sourceEntityType}
                    name={'Select Value'}
                    field={mappedFieldSet.source}
                    fileAppended={() => {}}
                    fieldChanged={(field, value) => {
                      entityFieldMapValueSet(value, index)
                    }}
                  />
                )}
              </View>
            )}
            <View>
              <MSFESButton
                icon={{ type: 'font-awesome', name: 'trash' }}
                type="delete"
                onPress={() => deleteMapRow(mappedFieldSet, index)}
              ></MSFESButton>
            </View>
            <Divider />
          </View>
        )
      })}
      <ListItemLinkButton
        title={`Add Mapped Field`}
        type="add"
        onPress={onAddMappedFieldPressed}
      />
    </View>
  )
})
