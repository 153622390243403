import _ from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  entityByName,
  sortListWithEntityType
} from '../../libraries/entityTools'
import SelectWithData from './SelectWithData'

//actions
import entityActions, { getFilterString } from '../../store/entity/actions'
import { sanitiseFilterString } from '../../libraries/sanitiseFilterString'
import { shouldRefetch } from './RadioList'
import PublicFormContext from '../../contexts/PublicFormContext'
import CrossFieldRenderAsNullContext from '../../contexts/CrossFieldRenderAsNullContext'
import { evaluateRule } from '../query/evaluateRule'
import { flattenEntity } from '../../helpers/entity'
import { listInstancesOfEventsUpcoming } from '../../store/calendar/actions'
import EntityEditContext from '../../contexts/EntityEditContext'

const _SelectList = (props, ref) => {
  const dispatch = useDispatch()

  const publicFormContext = useContext(PublicFormContext)

  const [, , , , , , , , , , , , filterRulesRequiredFromEffects] = useContext(
    EntityEditContext
  )

  const [nextPages, setNextPages] = useState({})
  const allEntityTypes = useSelector((state) =>
    _.get(state, 'entityTypes.data', null)
  )
  const recurringEventDefinitions = useSelector((state) =>
    _.get(state, 'calendar.eventDefinitions.recurringEvents', [])
  )

  const sourceNames = useMemo(() => {
    return _.castArray(props.data.field.field_data.params.sourceName)
  }, [props.data.field.field_data.params.sourceName])

  const [loading, setLoading] = useState(false)
  const [loadingFuture, setLoadingFuture] = useState(false)
  const [recurringEventEntities, setRecurringEventEntities] = useState([])
  useEffect(() => {
    if (props.data.field?.field_data?.params?.loadFutureEntities) {
      const matchingRecurringEvents = recurringEventDefinitions?.filter((re) =>
        sourceNames.includes(re.entityType?.name)
      )
      if (matchingRecurringEvents?.length && !recurringEventEntities.length) {
        setLoadingFuture(true)
        dispatch(
          listInstancesOfEventsUpcoming(
            matchingRecurringEvents.map((r) => r.id)
          )
        ).then((upcomingResponse) => {
          setLoadingFuture(false)
          setRecurringEventEntities(upcomingResponse.data)
        })
      }
    }
  }, ['', sourceNames, recurringEventDefinitions])

  const filterRule =
    props?.data?.field?.field_data?.params?.optionsFilter?.json?.logic ||
    (filterRulesRequiredFromEffects ?? []).find(
      (f) =>
        f.field.field_data.property === props?.data?.field?.field_data?.property
    )?.optionsFilter?.json?.logic

  const needsFieldsToSort = useMemo(() => {
    return _.some(sourceNames, (sourceName) => {
      const entityType = allEntityTypes.find((a) => a.name === sourceName)
      const sortField = _.get(
        entityType,
        'object_data.sort_by_field.field_data.property',
        null
      )

      return sortField
    })
  }, [allEntityTypes])

  const filter = filterRule || needsFieldsToSort ? { detailed: true } : null

  const entityTypes = sourceNames.map((s) => entityByName(s, allEntityTypes))

  const filterString = sanitiseFilterString(getFilterString(null, filter))

  const sourceListRoots = sourceNames.map((entityTypeName) => {
    return useSelector((state) =>
      entityTypeName
        ? _.get(state.entities, `[${entityTypeName + filterString}]`, {
            data: []
          })
        : []
    )
  })

  const fetchNextPage = (index) => {
    if (_.get(nextPages, index, false) !== -1) {
      let pageToFetch

      try {
        pageToFetch = sourceListRoots[index].meta.current_page + 1
      } catch (e) {
        // doesn't matter.
        pageToFetch = nextPages[index]
      }

      if (
        !pageToFetch ||
        pageToFetch > sourceListRoots[index].meta?.last_page
      ) {
        pageToFetch = 1
      }

      setLoading(true)
      dispatch(
        entityActions.listEntity(
          { name: sourceNames[index] },
          { filter, page: pageToFetch, detailed: filter?.detailed },
          undefined,
          publicFormContext.entityType,
          {
            tenantId: publicFormContext.tenantId,
            tenantHmac: publicFormContext.tenantHmac
          }
        )
      ).then((response) => {
        setLoading(false)
        if (!nextPages[index] || nextPages[index] <= response.meta.last_page) {
          if (response.meta.current_page < response.meta.last_page) {
            setNextPages((nextPages) => ({
              ...nextPages,
              ...{ [index]: response.meta.current_page + 1 }
            }))
          } else {
            setNextPages((nextPages) => ({ ...nextPages, ...{ [index]: -1 } }))
          }
        } else {
          setNextPages((nextPages) => ({ ...nextPages, ...{ [index]: -1 } }))
        }
      })
    }
  }

  useEffect(() => {
    sourceListRoots.map((sourceListRoot, index) => {
      if (shouldRefetch(sourceListRoot)) {
        fetchNextPage(index)
      } else {
        setNextPages((nextPages) => ({ ...nextPages, ...{ [index]: 1 } }))
      }
    })
  }, [])

  const sourceData = useMemo(() => {
    const data = _.flatten([
      ...sourceListRoots.map((sourceListRoot, index) => {
        const data = sortListWithEntityType(
          _.get(sourceListRoot, 'data', []),
          entityTypes[index]
        )

        return data
      }),
      ...recurringEventEntities
    ])

    const filteredData = filterRule
      ? data.filter((d) =>
          evaluateRule(filterRule, {
            ...flattenEntity(d, false),
            field: flattenEntity(props.data.entity, false)
          })
        )
      : data

    return filteredData
  }, [sourceListRoots, filterRule])

  const groupByFunction =
    sourceListRoots.length > 1
      ? (f) => {
          return f.entity_type.label
        }
      : undefined

  const { crossFieldRenderAsNull } = useContext(CrossFieldRenderAsNullContext)
  const skipStaticRendering =
    _.get(
      crossFieldRenderAsNull,
      `suppressObject[${props.data?.field?.field_data?.property}]`,
      true
    ) === false

  return skipStaticRendering ? null : (
    <SelectWithData
      {...props}
      dataRows={sourceData}
      groupByFunction={groupByFunction}
      labelField={'display_name'}
      onEndReached={() => {
        // console.log("ONENDREACHED")
        sourceListRoots.map((sourceListRoot, index) => {
          fetchNextPage(index)
        })
      }}
      loading={loadingFuture || loading}
      value={props.value}
    />
  )
}
export const SelectList = React.forwardRef(_SelectList)
