// libraries
import React from 'react'
import _ from 'lodash'
import { View } from 'react-native'
import { EntityTypeFieldPicker } from './EntityTypeFieldPicker'

import QueryEditorField from './QueryEditorField'
import MSFESInput from '../MSFESInput'
export const ApplyFormulaToField = (props) => {
  const { entityType, value, changeHandler } = props

  const entityTypeFields = (entityType?.fields ?? []).filter((field) => {
    return field.field_data.type === 'select-list'
  })
  const sourceValue = _.get(value, 'source', null)

  const entityFieldMapSourceChosen = (valueChosen) => {
    const currentValue = { ...value }
    currentValue.source = valueChosen
    changeHandler(currentValue)
  }

  const onRulesChanged = (rules) => {
    const currentValue = { ...value }
    currentValue.rules = rules
    changeHandler(currentValue)
  }

  const fullField = entityTypeFields.find(
    (field) => field.field_data.property === value?.source?.field_data.property
  )

  return (
    <View>
      <EntityTypeFieldPicker
        entityTypeFields={entityTypeFields}
        placeholder="Source Field"
        value={sourceValue}
        onEntityTypeFieldSelected={(value) => entityFieldMapSourceChosen(value)}
      />
      <MSFESInput
        InputComponent={QueryEditorField}
        key="options-filter"
        label={'Options filter'}
        title={'Options filter'}
        helpText={'Options must evaluate to true to show in the picker.'}
        changeHandler={(value) => {
          onRulesChanged(value)
        }}
        data={{
          entity: {
            entity_type_target: {
              name: _.get(fullField, 'field_data.params.sourceName', null)
            }
          }
        }}
        defaultValue={value?.rules}
      />
    </View>
  )
}
