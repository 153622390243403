import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { ListItem } from 'react-native-elements'
import { View } from 'react-native'
import entityActions from '../store/entity/actions'
import MSFESAvatar from './MSFESAvatar'
import { getLabel, simplifyObject } from './fields/SelectWithData'
import MSFESNestedAddEntity from './MSFESNestedAddEntity'
import { viewMode } from '../screens/EntityAddComponent'
import CrossFieldReferencesContext from '../contexts/CrossFieldReferencesContext'
import CrossFieldRenderAsNullContext from '../contexts/CrossFieldRenderAsNullContext'
import PublicFormContext from '../contexts/PublicFormContext'
import entityTypeActions from '../store/entity-type/actions'
import { useNavigation, useRoute } from '@react-navigation/native'
import MSFESButton from './MSFESButton'
import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'

const useGetRoute = () => {
  try {
    const route = useRoute()

    return route
  } catch (e) {
    return null
  }
}
const ViewListInline = (props) => {
  const route = useGetRoute()

  const dispatch = useDispatch()
  const entityTypes = useSelector((state) =>
    _.get(state, 'entityTypes.data', null)
  )

  // list entities of this entity type, where source field matches the ID
  // e.g. they are children.
  // props.data.field.field_data.params.sourceName

  const [inlineEntities, setInlineEntities] = useState(null)

  const publicFormContext = useContext(PublicFormContext)

  const [publicFormTargetEntityType, setPublicFormTargetEntityType] = useState(
    null
  )
  useEffect(() => {
    if (publicFormContext.tenantHmac) {
      dispatch(
        entityTypeActions.getEntityType(
          props.data.field.field_data.params.sourceName,
          true,
          {
            hmac: route?.params?.hmac,
            tenantId: route?.params?.tenantId,
            callingEntityType: publicFormContext.entityType.name
          }
        )
      ).then((response) => {
        setPublicFormTargetEntityType(response.data)
      })
    }
  }, [''])

  const { entityType: discoveredEntityType } = useDiscoverEntityEntityType(
    null,
    null,
    props.data.field.field_data.params.sourceName,
    null
  )

  const targetEntityType = useMemo(() => {
    if (publicFormContext.tenantHmac) {
      return publicFormTargetEntityType
    } else {
      return discoveredEntityType
    }
  }, [entityTypes, publicFormTargetEntityType, publicFormContext.tenantHmac])

  const navigateOnClick =
    props.data?.field?.field_data?.params?.navigateOnClick ?? false

  const targetEntityFieldName = _.get(
    props,
    'data.field.field_data.params.targetEntityField.field_data.property',
    ''
  )

  const sourceFieldName = _.get(
    props,
    'data.field.field_data.params.sourceEntityField.id',
    'id'
  )
  const sourceId =
    sourceFieldName == 'id'
      ? simplifyObject(props.data.entity)
      : _.get(
          props.data,
          `entity.${sourceFieldName}`,
          props.data?.entity?.id ?? props.data?.entity?.offline_id
        )

  const { crossFieldReferences, setCrossFieldReferences } = useContext(
    CrossFieldReferencesContext
  )

  const refreshListContents = (onChangedResponse) => {
    if (onChangedResponse?.data?.id) {
      // a child record was added or updated. We add the reference to this record
      // so the server can remake the connection when it's saved.
      const suppliedValue = props.defaultValue
        ? _.castArray(props.defaultValue)
        : []
      const newValue = [...suppliedValue, onChangedResponse?.data?.id]

      props.changeHandler(newValue)

      if (
        crossFieldReferences[props.data.field.field_data.property][
          'successHandler'
        ]
      ) {
        crossFieldReferences[props.data.field.field_data.property][
          'successHandler'
        ](onChangedResponse)
      }
    }

    const filter = {
      [targetEntityFieldName ?? 'id']: [
        sourceId?.id ?? sourceId?.offline_id ?? sourceId,
        props.data?.entity?.id,
        props.data?.entity?.offline_id
      ].join()
    }
    dispatch(
      entityActions.listEntity(
        targetEntityType,
        {
          filter
        },
        undefined,
        publicFormContext.entityType,
        {
          tenantId: publicFormContext.tenantId,
          tenantHmac: publicFormContext.tenantHmac
        }
      )
    ).then((response) => {
      setInlineEntities(
        (response.data ?? []).sort((a, b) => a.id - b.id).reverse()
      )
    })
  }
  useEffect(() => {
    // on mount
    if (targetEntityType) {
      refreshListContents()
    }
  }, [targetEntityType])

  // go to the target entity, and filter by the source ID (on the linked field).
  // e.g. source is truck, ID is 5. Target entity linked field is truck. Target entity is truck report.
  //  Go to /truck_reports?truck=5

  const [showNestedAdd, setShowNestedAdd] = useState(false)
  const [showNestedView, setShowNestedView] = useState(false)

  const tenant = props.data.entity?.tenant

  const editable = props.editable

  const primaryAction = () => {
    if (navigateOnClick) {
      setShowNestedAdd('full')
    } else {
      setShowNestedAdd('framed')
    }
  }

  const navigation = useNavigation()

  useEffect(() => {
    setCrossFieldReferences((crossFieldReferences) => {
      const thisProperty = {
        ...crossFieldReferences[props.data.field.field_data.property]
      }
      thisProperty.primaryAction = primaryAction
      return {
        ...crossFieldReferences,
        [props.data.field.field_data.property]: thisProperty
      }
    })
  }, [''])

  const { crossFieldRenderAsNull } = useContext(CrossFieldRenderAsNullContext)

  const skipStaticRendering =
    _.get(
      crossFieldRenderAsNull,
      `suppressObject[${props.data.field.field_data.property}]`,
      true
    ) === false

  return (
    <>
      {!skipStaticRendering && (
        <View style={{ flex: 1 }}>
          {!!targetEntityType && (
            <MSFESButton
              disabled={!editable}
              small
              title={`Add New ${props.data?.field?.field_data?.title}`}
              containerStyle={{ padding: 0, flex: 1 }}
              onPress={primaryAction}
            />
          )}
          {inlineEntities?.map((item, index) => {
            return (
              <ListItem
                key={index.toString()}
                onPress={() => {
                  if (navigateOnClick) {
                    navigation.push('EntityListDetail', {
                      'split.id': item?.id,
                      mode: viewMode,
                      type: targetEntityType?.name
                    })
                  } else {
                    setShowNestedView(item)
                  }
                }}
              >
                {item?.image ? (
                  <MSFESAvatar
                    showLabel={false}
                    rounded={false}
                    size={item.imageSize}
                    source={item.image}
                  />
                ) : null}
                {item?.icon ? (
                  <MSFESAvatar
                    showLabel={false}
                    icon={{ name: item?.icon, type: 'font-awesome' }}
                  />
                ) : null}
                <ListItem.Content>
                  <ListItem.Title>
                    {getLabel(item, 'display_name', item.id)}
                  </ListItem.Title>
                </ListItem.Content>
              </ListItem>
            )
          })}
        </View>
      )}
      {showNestedView && !!targetEntityType && (
        <MSFESNestedAddEntity
          entityType={targetEntityType}
          tenant={tenant}
          mode={viewMode}
          id={showNestedView.id}
          fieldName={targetEntityFieldName}
          parentId={sourceId}
          onDismiss={() => setShowNestedView(false)}
          onChangedCallback={refreshListContents}
        />
      )}
      {!!showNestedAdd && !!targetEntityType && (
        <MSFESNestedAddEntity
          entityType={targetEntityType}
          tenant={tenant}
          fieldName={targetEntityFieldName}
          parentId={sourceId}
          frameType={showNestedAdd}
          onDismiss={() => setShowNestedAdd(false)}
          onChangedCallback={refreshListContents}
        />
      )}
    </>
  )
}
export default ViewListInline
