// libraries
import React, { useMemo, useState } from 'react'
import { Platform, View, TouchableOpacity } from 'react-native'
import MaskInput, { Masks, createNumberMask } from 'react-native-mask-input'
import { Icon } from 'react-native-elements'

export const nestedInputExtraProps = {
  inputInternalStyle: { backgroundColor: 'transparent' }
}

export const transformUnmaskedInput = (value, maskType) => {
  let transformValue = value == null || value == undefined ? '' : value

  if (transformValue?.display_value) {
    transformValue = transformValue.display_value
  }
  switch (maskType) {
    case 'dollar':
      if (('' + transformValue)?.indexOf('.') !== -1) {
        return transformValue
      } else if (transformValue == '') {
        return ''
      } else if (typeof transformValue === 'number') {
        return '' + transformValue
      } else {
        return transformValue + '.00'
      }
    default:
      return transformValue
  }
}
export const MaskedInput = (props) => {
  const { defaultValue, editable, onBlur, isDisabledFromPermissions } = props //child props
  const [isContentVisible, setIsContentVisible] = useState(false)

  const [localValue, setLocalValue] = useState(
    defaultValue?.masked
      ? defaultValue
      : {
          unmasked: transformUnmaskedInput(
            defaultValue ?? '',
            props.data?.field?.field_data?.params?.inputMask ||
              props.data?.field?.field_data?.params?.formatResultAs
          ),
          masked: undefined
        }
  )

  const mask = useMemo(() => {
    switch (
      props.data?.field?.field_data?.params?.inputMask ||
      props.data?.field?.field_data?.params?.formatResultAs
    ) {
      case 'phone':
        return Masks.USA_PHONE
      case 'number':
        return createNumberMask({
          suffix: props.data?.field?.field_data?.params?.inputMaskSuffix
            ? [' ' + props.data?.field?.field_data?.params?.inputMaskSuffix]
            : undefined,
          minDigits:
            props.data?.field?.field_data?.params?.inputMaskMinDigits ??
            undefined,
          maxDigits:
            props.data?.field?.field_data?.params?.inputMaskMaxDigits ??
            undefined,
          delimiter: ',',
          separator: '.',
          precision: 0
        })
      case 'custom':
        const toInterpret =
          props.data?.field?.field_data?.params?.inputMaskJS ?? []

        const symbols = toInterpret.split('')
        const output = symbols.map((symbol) => {
          switch (symbol) {
            case '9':
              return /\d/
            case 'a':
              return /^[a-z]$/
            default:
              return symbol
          }
        })
        return output
      case 'ssn':
        if (isContentVisible) {
          return [
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/
          ]
        } else if (!isContentVisible) {
          return [
            [/\d/],
            [/\d/],
            [/\d/],
            '-',
            [/\d/],
            [/\d/],
            '-',
            [/\d/],
            [/\d/],
            [/\d/],
            [/\d/]
          ]
        }
        break
      case 'dollar':
        return createNumberMask({
          prefix: ['$', ' '],
          delimiter: ',',
          separator: '.',
          precision: 2
        })
    }
  }, [props.data?.field?.field_data?.params?.inputMask, isContentVisible])

  const isSSN = props.data?.field?.field_data?.params?.inputMask === 'ssn'
  const isDisabled = !editable || isDisabledFromPermissions

  const handleIconPress = () => {
    setIsContentVisible((prevState) => !prevState)
  }

  const fieldValue =
    typeof localValue.unmasked === 'number'
      ? '' + localValue.unmasked
      : localValue.unmasked
  return (
    <View style={{ flex: 1, marginBottom: 20 }}>
      <View style={{ flexDirection: 'row', flex: 1 }}>
        <MaskInput
          disabled={isDisabled}
          value={fieldValue}
          showObfuscatedValue
          obfuscationCharacter="*"
          maskAutoComplete={true}
          style={{
            ...props.style,
            paddingLeft: 8,
            flex: 1,
            ...(isSSN
              ? { textAlign: 'center' }
              : undefined) /* centre align SSN. */
          }}
          onBlur={() => {
            if (localValue?.masked == undefined) {
              return // do not propagate undefined.
            }
            onBlur(
              Platform.OS === 'web'
                ? { target: { value: ` ${localValue?.masked}` } }
                : { nativeEvent: { text: localValue?.masked } }
            )
          }}
          onChangeText={(masked, unmasked) => {
            if (!isDisabled) {
              setLocalValue({ masked, unmasked })
            }
          }}
          mask={mask}
        />
        {isSSN && (
          <TouchableOpacity
            onPress={handleIconPress}
            style={{
              position: 'absolute',
              right: 40,
              height: '88%',
              justifyContent: 'center',
              paddingHorizontal: 5,
              paddingRight: 5
            }}
          >
            <Icon
              name={isContentVisible && isSSN ? 'visibility' : 'visibility-off'}
              type="material"
              color="#CCCCCC"
              iconStyle={{
                fontFamily: 'FontAwesome5Free-Regular',
                fontWeight: 'normal',
                marginLeft: 0,
                paddingTop: 4
              }}
              size={24}
            />
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}
